<template>
  <form id="login-form" class="form" @submit.prevent="handleSubmit">
    <div class="log_main">
      <div class="bindPhine_title">
        <p>为了帐号安全，请输入完整的手机号{{ phoneMask }}</p>
      </div>
      <div class="log_ui logTool">
        <div class="dsib log_tool_outer vm">
          <input
            class="txt username vm"
            type="text"
            id="area_code"
            readonly="readonly"
            style="width: 53px;"
            :value="areaCode"
            name="areaCode"
          />
          <input
            class="txt username vm"
            type="text"
            id="phone"
            placeholder="请输入完整手机号"
            style="width: 83%"
            required
            @input="updateStore('phone', $event.target.value)"
            tabindex="1"
          />
        </div>
      </div>

      <div class="append_op dsib">
        <div class="dsib autoLogSet" id="err">{{ errmsg }}</div>
      </div>

      <div class="logBtnOuter">
        <BtnWithLoading type="submit" class="btn">下一步</BtnWithLoading>
      </div>

      <div class="dsib">
        <div class="dsib autoLogSet" style="padding-top:30px; font-size: 12px;">
          不是我的手机号，或已更换手机号，请联系管理员设置或更新
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import mapState from '@/mixins/map-state'
import updateStore from '@/mixins/update-store'
import handleErr from '@/mixins/handle-err'

export default {
  mixins: [mapState, updateStore, handleErr],
  data() {
    return {
      isPending: false
    }
  },
  mounted() {
    if (!this.account || !this.phoneMask) {
      this.$router.replace('/login')
    }
  },
  methods: {
    handleSubmit() {
      let payload = {
        _csrf: this.csrf,
        challenge: this.challenge,
        LoginType: this.loginType
      }

      payload['accountName'] =
        this.account && this.encrypter.encrypt(this.account)
      payload['phone'] = this.phone && this.encrypter.encrypt(this.phone)

      payload['appId'] = this.appId
      payload['phoneCipher'] = this.phoneCipher
      payload['areaCode'] = this.areaCode

      this.isPending = true
      this.$api
        .verifyphone(payload)
        .then(res => {
          this.isPending = false
          console.log(res)
          res.data = res.data || {}
          if (res.data.errcode !== '0' && res.data.errmsg) {
            this.updateStore('errmsg', res.data.errmsg)
          }

          if (res.data.errcode === '0') {
            this.updateStore('errmsg', '')
            // 后端跳转
            // this.$router.push('/verifysms')
          }
        })
        .catch(err => {
          this.handleErr(err)
        })
    }
  }
}
</script>
